import React, { useState } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/layout/Title";
import usePutJSON from "../../utils/usePutJson";
import Cookies from "js-cookie";

export default function AccountSettings() {
  const [info, setInfo] = useState({
    name: "",
    phone: "",
    mail: "",
  });

  const { name, phone, mail } = info;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setInfo({ ...info, [name]: value });
  };
  const [sended, setSended] = useState(false);

  const id = Cookies.get("id");
  const { putData } = usePutJSON();
  const sendHandeller = async () => {
    // setSended(true);
    const data = {
      name: name,
      phone: phone,
      email: mail,
    };

    const res = await putData(`user/settings/updateProfile/${id}`, data);

    if (res) {
      setSended(true);
    }
  };
  return (
    <div div className=" min-h-screen bg-[#F2F3F7]">
      <Title text={`الحساب`} />
      {sended ? (
        <>
          <section className=" bg-white rounded-[10px] p-3 text-center w-full md:w-[500px] mx-auto my-32">
            <i className="fa-solid fa-circle-check text-[#0D8F75] text-[110px] my-10"></i>
            <h1 className=" text-[20px] font-[700] text-[#372732]  ">
              تم تعديل البيانات بنجاح
            </h1>
            <Link to="/">
              <button className="LogBtn">عودة الي الصفحة الرئيسية</button>
            </Link>
          </section>
        </>
      ) : (
        <>
          <div className=" my-10 rounded-[10px] bg-white p-[20px] w-full md:w-fit mx-auto ">
            <section //Titles
              className="text-center my-4"
            >
              <h1 className=" text-[20px] font-[700] text-[#254570] ">
                تعديل البيانات الشخصية
              </h1>
            </section>
            <section //form
              className=" w-full md:w-[500px]"
            >
              <section //name
                className=" my-4"
              >
                <h4 className=" imputTitle"> اسم المستخدم </h4>
                <input
                  className="input2"
                  name="name"
                  onChange={handelChange}
                  value={name}
                  type="text"
                  placeholder=" قم بكتابة اسم المستخدم"
                />
              </section>
              <section //phone
                className=" my-4"
              >
                <h4 className=" imputTitle"> رقم الهاتف </h4>
                <input
                  className="input2"
                  name="phone"
                  onChange={handelChange}
                  value={phone}
                  type="text"
                  placeholder=" قم بكتابة رقم الهاتف"
                />
              </section>
              <section //mail
                className=" my-4"
              >
                <h4 className=" imputTitle"> البريد الالكتروني </h4>
                <input
                  className="input2"
                  name="mail"
                  onChange={handelChange}
                  value={mail}
                  type="text"
                  placeholder=" قم بكتابة البريد الالكتروني"
                />
              </section>
            </section>
            <section //BTNs
              className=" my-4"
            >
              <button onClick={sendHandeller} className="LogBtn">
                تعديل
              </button>
            </section>
          </div>
        </>
      )}
    </div>
  );
}
