import React from "react";

export default function Tap({ name, val, tap, setTap }) {
  return (
    <button
      onClick={() => setTap(val)}
      className={` sh-2 h-[50px] w-1/2 md:w-[200px] lg:w-[250px] text-[24px] font-[700]
            ${
              +tap === +val
                ? "bg-[white] text-[#0D8F75]  "
                : "bg-[#F5F5F5] text-[#999999]"
            }
         `}
    >
      {name}
    </button>
  );
}
