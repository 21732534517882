import React from "react";

export default function Dep({ color, name, src }) {
  return (
    <button>
      <section
        style={{ backgroundColor: color }}
        className=" text-center pt-6 min-w-[120px] h-[150px] rounded-[10px]"
      >
        <h2 className="text-white text-[18px] mb-4 font-[700]">{name}</h2>
        <img
          alt={`department ${name}`}
          className=" w-[56px] mx-auto"
          src={src}
        />
      </section>
    </button>
  );
}
