import React from "react";

export default function Error() {
  return (
    <div className="flex items-center justify-center h-screen text-red-600 text-2xl md:text-4xl">
      <h1>
        هذه الصفحة غير موجودة{" "}
        <i className="fa-solid fa-triangle-exclamation mx-2"></i>
      </h1>
    </div>
  );
}
