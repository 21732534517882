import React, { createContext, useState } from "react";

export const load = createContext();

export default function LoadStore({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <load.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </load.Provider>
  );
}
