import React, { useState } from "react";
import Tap from "./Tap";
import Coups from "./Coups";
import Stores from "./Sors";

export default function Fav() {
  const [tap, setTap] = useState(1);

  const handelTap = (val) => {
    setTap(val);
  };
  return (
    <div className=" p-3">
      <div className=" mb-5 flex items-center justify-center">
        <Tap name={"كوبونات"} val={1} tap={tap} setTap={handelTap} />
        <Tap name={"متاجر"} val={2} tap={tap} setTap={handelTap} />
      </div>
      {+tap === 1 && <Coups />}
      {+tap === 2 && <Stores />}
    </div>
  );
}
