import React, { useEffect, useState } from "react";
import VerticalCoupon from "../../components/assets/VerticalCoupon";
import useFetch from "../../utils/useGet";

export default function Points() {
  const [codes, setCodes] = useState([]);
  const { data } = useFetch(`user/coupons/all`);

  useEffect(() => {
    if (data) {
      setCodes(data);
    }
  }, [data]);

  return (
    <div className=" p-3">
      <h1 className="text-[#0D8F75] my-3 text-[23px] font-[700] ">
        اكتشف عروض الكاش باك
      </h1>
      <div className=" flex items-center justify-start flex-wrap">
        {codes.map((e, index) => (
          <VerticalCoupon
            key={index}
            code={e.code}
            name={e.name}
            src={
              e.store
                ? `${process.env.REACT_APP_URL}/${e.store.image_path}`
                : "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain"
            }
            desc={e.description}
            percent={`${e.discount}%`}
          />
        ))}
      </div>
    </div>
  );
}
