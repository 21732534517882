import React from "react";
import { Link } from "react-router-dom";

export default function StoreHorzint({ name, desc, src, id }) {
  return (
    <Link className="mx-auto " to={`/store?id=${id}`}>
      <section className=' mx-auto mt-[48px] relative text-center className=" hover:cursor-pointer hover:shadow-lg hover:scale-[1.02] transition-all ease-in-out duration-300  items-center gap-3 my-2  bg-white p-5 w-[170px] md:w-[270px]  rounded-[10px]'>
        <section className=" flex justify-center w-full">
          <img
            className=" mx-auto absolute top-[-41px] md:top-[-46px] h-[82px] md:h-[92px] w-[82px] md:w-[92px] rounded-full"
            src={src}
            alt="store"
          />
        </section>
        <section className=" mt-[41px] md:mt-[46px] w-full">
          <h1 className=" mb-2 subtitle">{name}</h1>
          <h4 className=" text-[#999999] text-[12px] font-[700]">{desc}</h4>
        </section>
      </section>
    </Link>
  );
}
