import React from "react";
import LinkBar from "./LinkBar";

export default function DownBar() {
  let links = [
    { name: "الرئيسية", link: "", icon: "fa-solid  fa-house" },
    { name: "النقاط", link: "points", icon: "fa-solid  fa-tag rotate-[270deg]" },
    // { name: "المفضلة", link: "fav", icon: "fa-regular  fa-heart" },
    { name: "المساعدة", link: "account", icon: "fa-regular  fa-user " },
  ];
  return (
    <div className=" w-full fixed bottom-0 left-0  flex justify-center">
      <div className=" flex items-center justify-between md:sh-2 w-full md:w-fit md:rounded-t-lg  px-4 bg-white  ">
        {links.map((e, index) => (
          <LinkBar key={index} icon={e.icon} li={e.link} text={e.name} />
        ))}
      </div>
    </div>
  );
}
