import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { load } from "../../utils/isLoadingStore";
import Cookies from "js-cookie";
import { isLogged } from "../../utils/inStore";
import Error from "../../components/err/Err";

export default function Login() {
  const [err, setErr] = useState(false);
  const [info, setInfo] = useState({
    user: "",
    password: "",
  });

  const { user, password } = info;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setInfo({ ...info, [name]: value });
  };

  const navigate = useNavigate();
  const { setIsLoading } = useContext(load);
  const logged = useContext(isLogged);
  const logInHandeller = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      email: user,
      password: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URL}/api/login`, requestOptions).then(
      (res) => {
        setIsLoading(false);
        if (res.status === 200) {
          res.json().then((data) => {
            Cookies.set("token", data.token);
            Cookies.set("id", data.user.id);
            logged.setLogged(true);
            navigate("/");
          });
        } else {
          setErr(true);
        }
      }
    );
  };
  if (logged.logged) {
    return <Error />;
  } else {
    return (
      <div className=" bg-white flex items-center min-h-screen justify-center">
        <div className=" px-2 md:border md:shadow-md md:rounded-md w-full md:w-fit my-2">
          <section>
            <img
              className=" w-[250px] mx-auto"
              src="./Images/logo.jpg"
              alt="Logo"
            />
          </section>
          <section className=" w-full p-2   md:w-[500px]">
            <h1 className=" loginTitle">تسجيل دخول</h1>
            <h4 className=" subTextLog">
              قم بتسجيل الدخول والحصول علي كوبون الخصم
            </h4>
          </section>
          <div //from
            className=" p-2"
          >
            <section //user
              className=" my-4"
            >
              <h4 className=" imputTitle">البريد الالكتروني</h4>
              <input
                className="input"
                name="user"
                onChange={handelChange}
                value={user}
                type="text"
                placeholder=" قم بكتابة البريد الالكتروني"
              />
            </section>
            <section //password
              className=" my-4"
            >
              <h4 className=" imputTitle"> كلمة المرور</h4>
              <input
                className="input"
                name="password"
                onChange={handelChange}
                value={password}
                type="password"
                placeholder="قم بكتابة كلمة المرور الخاصة بك"
              />
            </section>
            <section //BTNs
              className=" my-4"
            >
              <button onClick={logInHandeller} className="LogBtn">
                تسجيل الدخول
              </button>
              <section className=" my-2  font-[700] text-[15px]">
                {" "}
                <Link to="/signup">
                  <span className=" text-[#254570]">ليس لديك حساب؟ </span>
                  <span className=" text-[#0D1827]">انشاء حساب</span>{" "}
                </Link>
              </section>

              {/* <button className="LogSubBtn"> الدخول كزائر</button> */}
            </section>
            {err && (
              <section className=" my-2 bg-red-500/80 rounded-md w-4/5 p-2 text-sm text-white flex items-center">
                <section className=" bg-white text-red-500/80 ml-2 w-6 h-6 rounded-full flex items-center justify-center">
                  <i class="fa-solid fa-exclamation"></i>
                </section>
                كلمة السر او البريد الالكتروني خطأ
              </section>
            )}
          </div>
        </div>
      </div>
    );
  }
}
