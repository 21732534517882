import React from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className=" flex items-center justify-between sh-2 w-full fixed top-0 left-0 p-4 bg-white ">
      <section className="  bg-[#E7F4F1] flex items-center justify-center h-[48px] w-[48px] rounded-full">
        <img
          className=" w-[29px] h-[19px] rotate-[315deg]"
          src="./Images/1.png"
          alt="logo"
        ></img>{" "}
      </section>
      <Link to="/search">
        <section className=" flex items-center justify-start h-[34px] w-[230px] md:w-[360px] lg:w-[460px]  rounded-full bg-[#FAFAFA]">
          <i className="fa-solid fa-magnifying-glass text-[#9A9A9A] tex-[16px] mx-2 font-[400]"></i>
          <h4 className=" text-[#9A9A9A] tex-[16px] mx-2 font-[400]">
            قم بالبحث هنا
          </h4>
        </section>
      </Link>
      <Link to="/notifications">
        <section className=" mx-2">
          <i className="fa-regular fa-bell text-2xl text-[#0D8F75]"></i>
        </section>
      </Link>
    </div>
  );
}
