import React from "react";
import { Link } from "react-router-dom";

export default function Title({ text }) {
  return (
    <div className="  w-full  flex items-center bg-[#0D8F75] p-6">
      <Link to="/">
        <section className="  h-[40px] w-[40px] rounded-[10px] bg-white flex items-center justify-center text-[#0D8F75]">
          <i className="fa-solid fa-angle-right"></i>
        </section>
      </Link>
      <section className=" w-full text-white text-[25px] font-[700] text-center">
        {text}
      </section>
    </div>
  );
}
