import React, { useState } from "react";
import Title from "../../components/layout/Title";
import { Link } from "react-router-dom";
import usePostJSON from "../../utils/usePostJson";

export default function Help() {
  const [info, setInfo] = useState({
    title: "",
    desc: "",
  });

  const { title, desc } = info;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setInfo({ ...info, [name]: value });
  };
  const [sended, setSended] = useState(false);

  const {postData} = usePostJSON();

  const sendHandeller = async () => {
    const data = {
      title: title,
      details: desc,
    };
    const res = await postData("user/support/create", data);
    if (res) {
      setSended(true);
    }
  };
  return (
    <div div className=" min-h-screen border-b bg-[#F2F3F7]">
      <Title text={`مركز المساعدة`} />
      {sended ? (
        <>
          <section className=" bg-white rounded-[10px] p-3 text-center w-full md:w-[500px] mx-auto my-32">
            <i className="fa-solid fa-circle-check text-[#0D8F75] text-[110px] my-10"></i>
            <h1 className=" text-[20px] font-[700] text-[#372732]  ">
              تم ارسال المشكلة بنجاح
            </h1>
            <h4 className=" text-[14px] font-[700] text-[#CCCCCC] my-4 ">
              سيتم التواصل معك بعد مراجعة المشكلة
            </h4>
            <Link to="/">
              <button className="LogBtn">عودة الي الصفحة الرئيسية</button>
            </Link>
          </section>
        </>
      ) : (
        <>
          <div className=" my-10 rounded-[10px] bg-white p-[20px] w-full md:w-fit mx-auto ">
            <section //Titles
              className="text-center my-4"
            >
              <h1 className=" text-[20px] font-[700] text-[#254570] ">
                الابلاغ عن مشكلة
              </h1>
            </section>
            <section //form
              className=" w-full md:w-[500px]"
            >
              <section //title
                className=" my-4"
              >
                <h4 className=" imputTitle"> عنوان المشكلة </h4>
                <input
                  className="input2"
                  name="title"
                  onChange={handelChange}
                  value={title}
                  type="text"
                  placeholder=" قم بكتابة عنوان المشكلة  "
                />
              </section>
              <section //desc
                className=" my-4"
              >
                <h4 className=" imputTitle"> تفاصيل المشكلة </h4>
                <textarea
                  className="input3"
                  name="desc"
                  onChange={handelChange}
                  value={desc}
                  rows={7}
                  placeholder=" قم بكتابة  تفاصيل المشكلة  "
                />
              </section>
            </section>
            <section //BTNs
              className=" my-4"
            >
              <button onClick={sendHandeller} className="LogBtn">
                ارسال
              </button>
            </section>
          </div>
        </>
      )}
    </div>
  );
}
