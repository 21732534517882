import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../utils/inStore";
import DownOutPut from "../assets/DownOutPut";
import { Link, useLocation } from "react-router-dom";

export default function LogLayout() {
  const { logged } = useContext(isLogged);
  const [open, setOpen] = useState(false);
  const path = useLocation().pathname;
  console.log(path);

  useEffect(() => {
    setOpen(false);
    if (!logged) {
      console.log("a");
      if (path !== "/login" && path !== "/signup") {
        setOpen(true);
        console.log("b");
      }
    }
  }, [logged, path]);

  return (
    <div>
      <DownOutPut open={open}>
        <img src="./Images/logo.jpg" className=" h-56  mx-auto " alt="Logo" />
        <h1 className="loginTitle text-center"> انت لست مسجل</h1>
        <h4 className=" text-center mb-12 subTextLog">
          {" "}
          قم بالتسجيل الان ومتابعة افضل العروض
        </h4>
        <div className="my-4">
          <Link to={"/login"}>
            <button className="LogBtn">تسجيل الدخول</button>
          </Link>
          <Link to={"/signup"}>
            <button className="LogSubBtn">انشاء حساب</button>
          </Link>
        </div>
      </DownOutPut>
    </div>
  );
}
