import React, { useEffect, useState } from "react";
import Title from "../../components/layout/Title";
import Notifi from "./Notifi";
import useFetch from "../../utils/useGet";
import usePost from "../../utils/usePost";

export default function NotiFication() {
  const [notFis, setNotifis] = useState([]);
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch("user/notifications/myNotifacation", reload);

  const re = () => {
    setRelaod(!reload);
  };

  const { postData } = usePost();

  const markReadHandeller = async () => {
    const res = await postData("user/notifications/mark-as-read");
    if (res) {
      re()
     }
  };

  useEffect(() => {
    if (data) {
      setNotifis(data);
    }
  }, [data]);
  return (
    <div className=" min-h-screen bg-[#F2F3F7]">
      <Title text="الاشعارات" />
      <div className=" p-5">
        <section //make it readed
          className=" flex justify-end"
        >
          <button
            onClick={markReadHandeller}
            className="text-white text-[13px] rounded-[10px] font-[700] bg-[#0D8F75] p-4"
          >
            تحديد الكل كمقروء
          </button>
        </section>
        {notFis.map((e, index) => (
          <Notifi
            key={index}
            status={e.status}
            name={e.title}
            time={e.created_at.substring(0, 10)}
            desc={e.subject}
          />
        ))}
      </div>
    </div>
  );
}
