import React, { useState } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/layout/Title";

export default function Delete() {
  const [sended, setSended] = useState(false);

  const sendHandeller = () => {
    setSended(true);
  };
  return (
    <div div className=" min-h-screen bg-[#F2F3F7]">
      <Title text={`حذف الحساب`} />
      {sended ? (
        <>
          <section className=" bg-white rounded-[10px] p-3 text-center w-full md:w-[500px] mx-auto my-32">
            <i className="fa-solid fa-circle-check text-[#0D8F75] text-[110px] my-10"></i>
            <h1 className=" text-[20px] font-[700] text-[#372732]  ">
              تم حذف الحساب بنجاح
            </h1>
            <Link to="/login">
              <button className="LogBtn">عودة الي تسجيل الدخول</button>
            </Link>
          </section>
        </>
      ) : (
        <>
          <section className=" bg-white rounded-[10px] p-3 text-center w-full md:w-[500px] mx-auto my-32">
            <i className="fa-solid fa-circle-xmark text-[#FF0000] text-[110px] my-10"></i>
            <h1 className=" text-[20px] font-[700] text-[#372732] mb-10  ">
              هل انت متاكد من حذف الحساب ؟
            </h1>

            <button onClick={sendHandeller} className="LogBtn my-2">
              نعم انا متاكد
            </button>
            <Link to="/passwsord-settings">
              <button className="LogSubBtn my-2">لا لن احذف حسابي</button>
            </Link>
          </section>
        </>
      )}
    </div>
  );
}
