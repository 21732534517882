import React, { useEffect, useState } from "react";
import StoreWide from "../Home/StoreWide";
import useFetch from "../../utils/useGet";

export default function Stores() {
  const [stores, setStores] = useState([]);
  const { data } = useFetch("user/stores/store-favorites");

  useEffect(() => {
    if (data) {
      setStores(data);
    }
  }, [data]);
  return (
    <div>
      <div className="  flex items-center justify-start flex-wrap">
        {stores.map((e, index) => (
          <StoreWide
            id={e.store.id}
            key={index}
            name={e.store.name}
            src={`${process.env.REACT_APP_URL}/${e.store.image_path}`}
            desc={e.store.description}
          />
        ))}
      </div>
      {stores.length === 0 && (
        <div className=" text-center my-20 ">
          <i className="fa-solid fa-heart text-[#BFBFBF] text-8xl my-4 " />
          <h1 className=" text-[22px] font-[700] my-3">انت لا تتابع اي متجر</h1>
          <h4 className="text-[18px] text-[#CCCCCC] font-[700] my-4">
            تابع المتاجر للحصول علي مفضلاتك
          </h4>
        </div>
      )}
    </div>
  );
}
