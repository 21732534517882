import React, { useEffect, useState } from "react";
import Title from "../../components/layout/Title";
import useFetch from "../../utils/useGet";

export default function Policies() {
  let [policies, setPolicises] = useState("");
  const { data } = useFetch("user/terms/all");

  useEffect(() => {
    if (data) {
      setPolicises(data.content);
    }
  }, [data]);
  return (
    <div div className=" min-h-screen bg-[#F2F3F7]">
      <Title text={`شروط الخصوصية`} />
      <div className=" my-10 rounded-[10px] bg-white p-[30px] w-full md:w-[500px] mx-auto ">
        <h1 className=" my-3 font-[700] text-[20px] text-[#254570]">{`شروط الخصوصية`}</h1>
        <p className=" text-[14px] leading-[25px] font-[400] text-[#868686] ">
          {policies}
        </p>
      </div>
    </div>
  );
}
