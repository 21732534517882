import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import Dep from "./Dep";
import DownOutPut from "../../components/assets/DownOutPut";
import FilterBT from "./FilterBT";
import StoreWide from "./StoreWide";
import StoreHorzint from "./StoreHorzint";
import useFetch from "../../utils/useGet";

export default function Home() {
  // const [reload, setRelaod] = useState(false);
  const { data } = useFetch("user/advertisements/all");

  let [imgs, setImages] = useState([]);

  useEffect(() => {
    if (data) {
      setImages(data.reverse());
    }
  }, [data]);
  let [deps, setDeps] = useState([]);
  const allDeps = useFetch("user/sections/all").data;
  const [sectionId, setSectionId] = useState("all");

  useEffect(() => {
    if (allDeps) {
      setDeps(allDeps);
    }
  }, [allDeps]);

  const [openFilter, setOpenFilter] = useState(false);
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const [filter, setFilter] = useState("newest");

  const setFilterHndeller = (val) => {
    setFilter(val);
    toggleOpenFilter();
  };
  const filters = [
    { name: "تمت اضافته مؤخرا", val: "newest" },
    { name: "الاكثر شيوعا", val: "pouplar" },
    { name: "الاكثر استخداما", val: "usest" },
    { name: "الاكثر متابعة", val: "followest" },
    { name: "ابجديا", val: "alphapetclly" },
  ];

  const [shapeWide, setShapeWide] = useState(true);

  const [stores, setStores] = useState([]);
  const [storesForSearsh, setStoresForSearsh] = useState([]);
  const allStores = useFetch("user/stores/all").data;
  useEffect(() => {
    if (allStores) {
      setStores(allStores);
      setStoresForSearsh(allStores);
    }
  }, [allStores]);

  useEffect(() => {
    let searched = storesForSearsh;
    if (sectionId !== "all") {
      searched = searched.filter((e) => e.section_id == sectionId);
    }
    setStores(searched);
  }, [sectionId]);
  return (
    <div>
      <section //ADs
        className=" p-3"
      >
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 4000, // Time delay in milliseconds
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {imgs.map((e, index) => (
            <SwiperSlide key={index}>
              <img
                className="w-full h-[200px] md:h-[230px] md:w-[90%] rounded-md mx-auto"
                src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                alt="AD"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section //departs
        className=" my-4 p-3"
      >
        <div //titles
        >
          <h1 className="subtitle">الاقسام</h1>
        </div>
        <div //deps
          className=" flex gap-2 my-3 w-full overflow-x-auto"
        >
          <button onClick={() => setSectionId("all")}>
            <Dep color={"#2761FF"} src={"./Images/icon.PNG"} name="الكل" />
          </button>
          {deps.map((e, index) => (
            <button onClick={() => setSectionId(e.id)}>
              <Dep
                key={index}
                color={e.description}
                src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                name={e.name}
              />
            </button>
          ))}
        </div>
      </section>
      <section //stores
        className=" "
      >
        <div //titles
          className=" px-3 flex items-center justify-between"
        >
          <h1 className="subtitle">{stores.length} متجر</h1>
          <div className=" flex items-center justify-around gap-3">
            <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
              <i className="fa-solid fa-sliders"></i>
            </section>
            {/* <button onClick={toggleOpenFilter}>
              <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
                <i className="fa-solid fa-filter"></i>
              </section>
            </button> */}
            <button onClick={() => setShapeWide(!shapeWide)}>
              <section className=" text-[#0D8F75] w-[38px] mx-2 h-[38px] rounded-[5px] bg-white flex items-center justify-center">
                <i
                  className={`fa-solid fa-pause text-xl ${
                    !shapeWide && "rotate-90"
                  }`}
                ></i>
              </section>
            </button>
          </div>
        </div>
        <div className=" px-3 w-full overflow-hidden flex flex-wrap gap-3 my-6">
          {stores.map((e, index) => {
            if (shapeWide) {
              return (
                <StoreWide
                  id={e.id}
                  key={index}
                  name={e.name}
                  src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                  desc={e.description}
                />
              );
            } else {
              return (
                <StoreHorzint
                  key={index}
                  id={e.id}
                  name={e.name}
                  src={`${process.env.REACT_APP_URL}/${e.image_path}`}
                  desc={e.description}
                />
              );
            }
          })}
        </div>
      </section>
      {
        <DownOutPut close={toggleOpenFilter} open={openFilter}>
          <div className=" px-3">
            <h1 className="loginTitle text-center">ترتيب حسب </h1>
            {filters.map((e) => (
              <FilterBT
                key={e.val}
                filter={filter}
                val={e.val}
                set={setFilterHndeller}
                text={e.name}
              />
            ))}
          </div>
        </DownOutPut>
      }
    </div>
  );
}
